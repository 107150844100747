<template>
  <div :class="$style.manageBox">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">{{
          $t('hat.clockRecord.electronicFence')
        }}</span>
      </template>
      <div :class="$style.manageContent" ref="content" slot="content">
        <div :class="$style.form">
          <a-form-model ref="ruleForm" :class="$style.formWrap">
            <a-form-model-item>
              <a-input
                style="width: 240px"
                v-model="input"
                @input.stop="() => handleSearch()"
                :placeholder="$t('hat.electronFence.electronFenceName')"
              >
                <a-icon slot="prefix" type="search" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                v-if="$p.action('CREATE')"
                type="primary"
                icon="plus"
                size="small"
                @click="addFenceHandle()"
                >{{ $t('hat.electronFence.add') }}</a-button
              >
            </a-form-model-item>
          </a-form-model>
        </div>
        <a-table
          :columns="columns"
          :dataSource="list"
          :pagination="pagination"
          :class="$style.table"
          @change="handleChange"
          :scroll="{ x: 1600 }"
          :loading="loading"
        >
          <template slot="action" slot-scope="record">
            <span :class="$style.buttonGroup" @click.stop>
              <a
                v-if="$p.action('UPDATE')"
                :class="$style.button"
                @click.stop="handleUpdateFence(record)"
                >{{ $t('hat.deviceManagement.edit') }}</a
              >
              <a :class="$style.button" @click.stop="openOverview(record)">{{
                $t('hat.electronFence.preview')
              }}</a>
              <a
                v-if="$p.action('DELETE')"
                :class="[$style.button, $style.red]"
                @click.stop="handleDelete(record)"
                >{{ $t('enterpriseManagement.camera.delete.txt') }}</a
              >
              <a v-if="$p.action('UPDATE')" @click.stop="handleCopy(record)">{{
                $t('hat.electronFence.copy')
              }}</a>
            </span>
          </template>
        </a-table>
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import dayjs from 'dayjs';
import {
  getFenceList,
  insertFence,
  updateFence,
  removeFence,
  copyFence,
  deleteBatch,
} from '@/services/smart-hat/geofence';
import { getMyDevice } from '@/services/smart-hat/device';
import { createFormModal, createModal } from '@triascloud/x-components';
import FenceForm from './components/form.vue';
import FenceMap from './components/form-map.vue';
import Tip from '@/components/tip';
import { CHART_DIALOG_KEY } from './components/util';

@Component({
  components: {
    LayoutContent,
  },
})
export default class ElectronFence extends Vue {
  list = [];
  input = '';
  loading = false;
  mounted() {
    this.getListData();
  }

  async getMyDevice() {
    await getMyDevice();
  }

  handleDelete(record) {
    const $t = this.$t;
    createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <span>
              {$t('enterpriseManagement.camera.delete.title')}
              {record.geofenceName}
            </span>
          </template>
          <span slot="subTxt">{$t('hat.electronFence.afterDeletion')}</span>
        </Tip>
      ),
      {
        title: $t('common.tips.prompt'),
        onOk: async () => {
          this.$message.success('删除成功！');
          await removeFence(record.geofenceId);
          this.getListData();
        },
      },
    );
  }

  async handleCopy(record) {
    await copyFence([record.geofenceId]);
    this.$message.success('操作成功！');
    this.getListData();
  }

  async getListData() {
    this.loading = true;
    const params = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.input) {
      params.input = this.input;
    }
    const { records, current, size, total } = await getFenceList(params);
    this.pagination.current = current;
    this.pagination.pageSize = size;
    this.pagination.total = total;
    this.list = records;
    this.loading = false;
  }
  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => this.$t('todo.flow.total', { total }),
  };

  handleChange(pagination) {
    this.pagination = pagination;
    this.getListData();
  }

  async openOverview(obj) {
    await this.editZoneMap({
      id: obj.geofenceId,
      title: obj.geofenceName,
      permission: obj.permission,
      type: CHART_DIALOG_KEY.READ,
    });
  }

  async addFenceHandle() {
    try {
      const title = this.$t('hat.electronFence.add');
      const result = await createFormModal(() => <FenceForm />, {
        width: '440px',
        title: title,
        maskClosable: false,
      });
      if (result) {
        const { geofenceId, permission } = await insertFence(result);
        this.getListData();
        await this.editZoneMap({
          id: geofenceId,
          title,
          permission,
        });
      }
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }

  async handleUpdateFence(obj) {
    const title = this.$t('hat.electronFence.editElectronFence');
    const result = await createFormModal(() => <FenceForm editData={obj} />, {
      width: '440px',
      title,
      maskClosable: false,
    });

    if (result) {
      const { permission } = await updateFence(result);
      this.getListData();
      await this.editZoneMap({
        id: obj.geofenceId,
        title,
        permission,
      });
    }
  }

  /** @name 编辑电子围栏地图 */
  async editZoneMap({ id, title, permission, type = CHART_DIALOG_KEY.UPDATE }) {
    const editModal = createModal(
      () => (
        <FenceMap
          id={id}
          permission={permission}
          type={type}
          onFinally={() => {
            editModal.handleClose();
            this.getListData();
          }}
        />
      ),
      {
        width: 1000,
        title,
        maskClosable: false,
        className: this.$style.fenceMap,
      },
    );
  }

  get columns() {
    const $t = this.$t;
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: $t('hat.clockRecord.index'),
        align: 'center',
        width: 80,
      },
      {
        width: 300,
        align: 'left',
        title: $t('hat.electronFence.name'),
        ellipsis: true,
        dataIndex: 'geofenceName',
        // customRender: (text, row, index) => <span>{index + 1}</span>,
      },
      {
        align: 'left',
        title: $t('hat.electronFence.activeArea'),
        dataIndex: 'allowZone',
      },
      {
        title: $t('hat.electronFence.forbiddenRegion'),
        dataIndex: 'prohibitZone',
      },
      {
        title: $t('hat.electronFence.available'),
        dataIndex: 'usable',
        ellipsis: true,
        width: 300,
        customRender: text => text + '台设备',
      },
      {
        title: $t('hat.electronFence.editTime'),
        dataIndex: 'updateTime',
        customRender: txt => dayjs(txt).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: $t('enterpriseManagement.camera.tableColumn.operation'),
        // width: 300,
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  handleSearch() {
    this.pagination.current = 1;
    this.getListData();
  }
  async handleUnbind(record) {
    const { geofenceId, geofenceName } = record;
    const text = `确认删除${geofenceName}？`;
    const tips = '删除后建议重新配置关联设备和考勤组中的电子围栏';
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: '提示',
          onOk: async () => {
            try {
              await deleteBatch(String(geofenceId));
              this.$message.success('电子围栏删除成功');
              this.getListData();
            } catch (error) {
              this.$message.warn('电子围栏删除失败');
            }
          },
        },
      );
    } catch {
      return false;
    }

    // try{
    //   await deleteBatch(String(geofenceId));
    //   this.$message.success('电子围栏删除成功');
    //   this.getListData();
    // }catch(error){
    //   this.$message.warn('电子围栏删除失败');
    // }
  }
}
</script>
<style lang="less" module>
.form {
  width: 100%;
  padding-bottom: 6px;
  .formWrap {
    display: flex;
    justify-content: space-between;
  }

  :global(.ant-form) {
    display: flex;
    align-items: center;
  }

  .btn {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.buttonGroup {
  display: flex;
  gap: 20px;
  .red {
    color: #f72f2f;
  }

  .button {
    position: relative;
  }

  .button:last-of-type {
    &::after {
      width: 0;
    }
  }
}

.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;

  .title {
    font-size: 16px;
  }

  .manageContent {
    padding: 20px 28px;
    min-width: 850px;
    // height: 100%;
    // .table {
    //   height: 94%;
    //   padding-bottom: 10px;
    // }
    :global {
      .ant-table-wrapper .ant-spin-container .ant-table-body,
      .ant-table-wrapper .ant-table-scroll .ant-table-body {
        flex: 1;
        overflow: auto !important;
        max-height: none !important;
      }
    }
  }
}

.fenceMap {
  :global {
    .ant-modal-body {
      padding: 0;
    }
  }
}
</style>
